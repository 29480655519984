<!-- src/layouts/AuthLayout.vue -->
<template>
  <div>
    <router-view />
    <!-- 자식 컴포넌트들이 렌더링되는 영역 -->
  </div>
</template>

<script>
export default {
  name: 'AuthLayout'
}
</script>
